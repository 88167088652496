import { ElementNode } from '@graphcommerce/graphcms-ui/components/RichText/types'
import {
  AddProductsToCartButton,
  ProductListQuery,
  useAddProductsToCartAction,
} from '@graphcommerce/magento-product'
import { ButtonProps } from '@mui/material'
import { ReactNode, SyntheticEvent, useState } from 'react'
import { AddToCartDialog } from '../../Dialogs/AddToCartDialog'

type InfoModal = {
  infoForm?: {
    scriptId: string
  } | null
  callADruggist?: {
    content: {
      raw: ElementNode
    }
  } | null
}

type AddToCartButton = {
  product: NonNullable<NonNullable<NonNullable<ProductListQuery['products']>['items']>[0]>
  label: ReactNode
} & ButtonProps

export type AddProductsToCartModalProps = InfoModal & AddToCartButton

export function AddProductsToCartButtonWithModal(props: AddProductsToCartModalProps) {
  const [open, setOpen] = useState(false)
  const { infoForm, callADruggist, product, label, ...buttonProps } = props
  const action = useAddProductsToCartAction(props)

  if (callADruggist && (product?.mos_rvh_code || product?.mos_rvg_code)) {
    return (
      <>
        <AddProductsToCartButton
          {...buttonProps}
          color='primary'
          {...action}
          product={product}
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
        >
          {label}
        </AddProductsToCartButton>
        <AddToCartDialog
          infoForm={infoForm}
          callADruggist={callADruggist}
          open={open}
          sku={product.sku}
          onClose={(e: SyntheticEvent<unknown, Event>) => {
            e.stopPropagation()
            e.preventDefault()
            setOpen(false)
          }}
          onSubmit={() => setOpen(false)}
          qty_pieces={product.qty_pieces}
          qty_unit={product.qty_unit}
        />
      </>
    )
  }

  return (
    <AddProductsToCartButton {...buttonProps} product={product}>
      {label}
    </AddProductsToCartButton>
  )
}
