import { ElementNode } from '@graphcommerce/graphcms-ui/components/RichText/types'
import { useFormAddProductsToCart } from '@graphcommerce/magento-product'
import { Trans } from '@lingui/react'
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogProps,
} from '@mui/material'
import { FormEvent, MouseEventHandler, useState } from 'react'
import { MoreInformationDialog } from './MoreInformationDialog/MoreInformationDialog'

export type AddToCartDialogProps = {
  open: boolean
  name?: string | null
  qty_pieces?: string | null
  qty_unit?: string | null
  sku?: string | null
  infoForm?: {
    scriptId: string
  } | null
  callADruggist?: {
    content: {
      raw: ElementNode
    }
  } | null
} & Partial<DialogProps>

export function AddToCartDialog(props: AddToCartDialogProps) {
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const { name, infoForm, callADruggist, sku, qty_pieces, qty_unit, onSubmit, ...dialogProps } =
    props

  const { setValue, handleSubmit } = useFormAddProductsToCart()
  const submit = handleSubmit(() => {})

  const handleAddToCart: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setValue(`cartItems.0.sku`, sku ?? '')
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    submit()
    onSubmit?.(e as unknown as FormEvent<HTMLDivElement>)
  }

  return (
    <>
      <Dialog
        {...dialogProps}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description-1 alert-dialog-description-2 alert-dialog-description-3'
      >
        <DialogTitle sx={(theme) => ({ p: theme.spacings.xs, pb: 0 })} id='alert-dialog-title'>
          Waarschuwing
        </DialogTitle>
        <DialogContent sx={(theme) => ({ p: theme.spacings.xs, pb: 0 })}>
          {(qty_pieces || qty_unit) && (
            // De tekst in de DialogContentText is tijdelijk gehardcode. Hier moet in de nabije toekomst een variabele voor komen uit de Backend.
            <DialogContentText id='alert-dialog-description-1'>
              Lees altijd voor gebruik de bijsluiter!
            </DialogContentText>
          )}
          <DialogContentText
            id='alert-dialog-description-3'
            sx={(theme) => ({ mt: theme.spacings.xs })}
          >
            <strong>
              <Trans id='Do you have a question about this product? Contact a druggist' />
            </strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            p: theme.spacings.xs,
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
          })}
        >
          <Button
            onClick={(e) => {
              setOpenInfoDialog(true)
              e.preventDefault()
              e.stopPropagation()
            }}
            autoFocus
            variant='text'
            color='primary'
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                mb: '5px',
              },
            })}
          >
            <Trans id='Yes, more information' />
          </Button>
          <Button
            onClick={handleAddToCart}
            autoFocus
            variant='pill'
            color='primary'
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                mb: '5px',
                ml: '0px !important',
              },
            })}
          >
            <Trans id='Add to Cart' />
          </Button>
        </DialogActions>
      </Dialog>
      <MoreInformationDialog
        name={name}
        openInfoDialog={openInfoDialog}
        setOpenInfoDialog={setOpenInfoDialog}
        infoForm={infoForm}
        callADruggist={callADruggist}
        onAddToCart={handleAddToCart}
      />
    </>
  )
}
