import { RichText } from '@graphcommerce/graphcms-ui'
import { ElementNode } from '@graphcommerce/graphcms-ui/components/RichText/types'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Container,
  DialogActions,
  Button,
} from '@mui/material'
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { RowFormsApp } from '../../GraphCMS/RowFormsApp/RowFormsApp'
import { ProductPageAccordionItem } from '../../Product/ProductPageAccordion/AccordionItem'

export type MoreInfoDialogProps = {
  name?: string | null
  openInfoDialog: boolean
  setOpenInfoDialog: Dispatch<SetStateAction<boolean>>
  onAddToCart?: MouseEventHandler<HTMLButtonElement>
  infoForm:
    | {
        scriptId: string
      }
    | null
    | undefined
  callADruggist:
    | {
        content: {
          raw: ElementNode
        }
      }
    | null
    | undefined
}

export function MoreInformationDialog(props: MoreInfoDialogProps) {
  const { name, openInfoDialog, setOpenInfoDialog, infoForm, callADruggist, onAddToCart } = props

  const content = callADruggist?.content
  const scriptId = infoForm?.scriptId

  const [isCallOpen, setIsCallOpen] = useState(true)
  const [isMailOpen, setIsMailOpen] = useState(false)

  const ref = useRef<HTMLElement>(null)

  const handleScroll = () => {
    ref.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollTo({ top: 0 })
    }, 20)
  }, [openInfoDialog])

  return (
    <Dialog
      open={openInfoDialog}
      onClose={(e: SyntheticEvent<unknown, Event>) => {
        e.preventDefault()
        e.stopPropagation()
        setOpenInfoDialog(false)
        setIsCallOpen(true)
        setIsMailOpen(false)
      }}
    >
      <Box ref={ref} sx={{ overflowY: 'auto' }}>
        <DialogTitle>{name}</DialogTitle>

        <Box
          sx={{ borderTop: '1px solid #00000015', borderBottom: '1px solid #00000015' }}
          onClick={() => {
            setIsCallOpen(!isCallOpen)
            setIsMailOpen(false)
            handleScroll()
          }}
        >
          <ProductPageAccordionItem
            title={i18n._(/* i18n */ 'Call a druggist')}
            expanded={isCallOpen}
          >
            <DialogContent sx={{ pb: 0 }}>
              <>
                {content && (
                  <Container>
                    <RichText
                      sxRenderer={{
                        table: (theme) => ({
                          '& tbody tr': {
                            border: '2px solid #ededed',
                          },
                          'tr:nth-of-type(odd)': {
                            backgroundColor: `${theme.palette.action.hover} !important`,
                          },
                          '& th': {
                            display: 'none',
                            fontWeight: 600,
                          },
                          '& th, & td': {
                            py: 1,
                          },
                          [theme.breakpoints.down('sm')]: {
                            '& td': {
                              wordBreak: 'break-word',
                            },
                          },
                        }),
                      }}
                      {...content}
                    />
                  </Container>
                )}
              </>
            </DialogContent>
          </ProductPageAccordionItem>
        </Box>
        <Box
          sx={{ borderBottom: '1px solid #00000015' }}
          onClick={() => {
            setIsMailOpen(!isMailOpen)
            setIsCallOpen(false)
            handleScroll()
          }}
        >
          <ProductPageAccordionItem
            title={i18n._(/* i18n */ 'Mail a druggist')}
            expanded={isMailOpen}
          >
            <RowFormsApp identity={scriptId ?? ''} />
          </ProductPageAccordionItem>
        </Box>
        <DialogActions
          sx={(theme) => ({
            padding: theme.spacings.xs,
          })}
        >
          <Button
            onClick={(e) => {
              setOpenInfoDialog(false)
              onAddToCart?.(e)
            }}
            autoFocus
            variant='pill'
            color='primary'
          >
            <Trans id='Add to Cart' />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
