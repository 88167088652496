import { Image, ImageProps } from '@graphcommerce/image'
import { ProductListItemFragment, useProductLink } from '@graphcommerce/magento-product'
import { extendableComponent, IconSvg, iconPlus, breakpointVal } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { ButtonBase, Typography, Box, styled, SxProps, Theme } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'
import { CallADruggistQuery } from '../Dialogs/MoreInformationDialog/CallADruggist.gql'
import { Money } from '../Product/Money'
import { AddProductsToCartButtonWithModal } from '../Product/ProductAddToCart/AddProductsToCartButtonWithModal'
import { useGetCurrentProductQtyFromCart } from '../Product/ProductAddToCart/useGetCurrentProductQtyFromCart'
import { PromotionLabel } from '../Product/PromotionLabel/PromotionLabel'
import { ProductListItemDaFragment } from './ProductListItemDa.gql'
import { ProductListPrice } from './ProductListPrice'

const { classes, selectors } = extendableComponent('ProductListItem', [
  'root',
  'item',
  'title',
  'titleContainer',
  'contents',
  'subtitle',
  'price',
  'overlayItems',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'imageContainer',
  'placeholder',
  'image',
  'discount',
] as const)

export type OverlayAreaKeys = 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight'

export type OverlayAreas = Partial<Record<OverlayAreaKeys, ReactNode>>

type StyleProps = {
  aspectRatio?: [number, number]
  imageOnly?: boolean
}

type BaseProps = PropsWithChildren<
  { subTitle?: ReactNode } & StyleProps &
    OverlayAreas &
    ProductListItemFragment &
    Pick<ImageProps, 'loading' | 'sizes' | 'dontReportWronglySizedImages'>
>

export type ProductListItemProps = ProductListItemDaFragment &
  BaseProps & {
    sx?: SxProps<Theme>
  } & CallADruggistQuery

const StyledImage = styled(Image)({})

export function ProductListItem(props: ProductListItemProps) {
  const {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    small_image,
    sku,
    name,
    price_range,
    stock_status,
    qty_pieces,
    qty_unit,
    label_display,
    label_qty,
    label_percent,
    label_price,
    promotion_type,
    has_cart_rule,
    has_catalog_rule,
    children,
    imageOnly = false,
    loading,
    sizes,
    dontReportWronglySizedImages,
    aspectRatio = [4, 3],
    callADruggist,
    form,
    sx = [],
  } = props

  const labelData = {
    label_display,
    label_qty,
    label_percent,
    label_price,
    promotion_type,
    price_range,
  }

  const productLink = useProductLink(props)
  const isPromotionProduct = Boolean(has_cart_rule || has_catalog_rule)
  const { cartItemQty } = useGetCurrentProductQtyFromCart({ sku })
  const hasReachedLimit = isPromotionProduct && cartItemQty === 10

  return (
    <ButtonBase
      href={productLink}
      sx={[
        (theme) => ({
          display: 'flex',
          position: 'relative',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          background: theme.palette.background.paper,
          p: 1,
          borderRadius: '10px',
          minWidth: 0,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={classes.root}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'grid',
          bgcolor: 'background.image',
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
            theme.breakpoints.values,
          ),
          p: 0,
          aspectRatio: '1',

          '& > picture': {
            gridArea: `1 / 1 / 3 / 3`,
          },
        })}
        className={classes.imageContainer}
      >
        {small_image ? (
          <StyledImage
            layout='fill'
            width={1}
            height={1}
            sizes={sizes}
            dontReportWronglySizedImages={dontReportWronglySizedImages}
            src={small_image.url ?? ''}
            alt={small_image.label ?? ''}
            className={classes.image}
            loading={loading}
            sx={{ objectFit: 'contain', aspectRatio: `${aspectRatio[0] / aspectRatio[1]}` }}
          />
        ) : (
          <Box
            sx={{
              gridArea: `1 / 1 / 3 / 3`,
              typography: 'caption',
              display: 'flex',
              textAlign: 'center',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'background.default',
              userSelect: 'none',
            }}
            className={`${classes.placeholder} ${classes.image}`}
          >
            <Trans id='No Image' />
          </Box>
        )}

        {!imageOnly && (
          <>
            <Box
              sx={{
                gridArea: `1 / 1 / 2 / 2`,
                zIndex: 1,
              }}
              className={classes.topLeft}
            >
              {topLeft}
            </Box>
            <Box
              sx={{
                justifySelf: 'end',
                textAlign: 'right',
                gridArea: `1 / 2 / 2 / 3`,
              }}
              className={classes.topRight}
            >
              {topRight}
            </Box>
            <Box
              sx={{
                alignSelf: 'flex-end',
                gridArea: `2 / 1 / 3 / 2`,
                zIndex: 0,
              }}
              className={classes.bottomLeft}
            >
              {bottomLeft}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                alignSelf: 'flex-end',
                gridArea: `2 / 2 / 3 / 3`,
                justifySelf: 'end',
              }}
              className={classes.bottomRight}
            >
              {bottomRight}
            </Box>
          </>
        )}
      </Box>

      {!imageOnly && (
        <>
          <Typography
            component='h2'
            variant='subtitle2'
            sx={(theme) => ({
              overflowWrap: 'break-word',
              width: '100%',
              lineHeight: '1.2em',
              mt: theme.spacings.xxs,
              mb: '4px',
            })}
            className={classes.title}
          >
            {name}
          </Typography>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: 'auto',
            }}
          >
            <Typography
              className={classes.contents}
              variant='caption'
              color='text.secondary'
              sx={{ width: '100%' }}
            >
              {qty_pieces} {qty_unit}
            </Typography>
            <Box className={classes.subtitle} sx={{ alignSelf: 'flex-end', mb: '3px' }}>
              {price_range.minimum_price.regular_price.value !==
                price_range.minimum_price.final_price.value && (
                <Money {...price_range.minimum_price.regular_price} />
              )}
            </Box>

            <ProductListPrice {...price_range.minimum_price} />

            {sku && name && (
              <AddProductsToCartButtonWithModal
                infoForm={form}
                callADruggist={callADruggist}
                disabled={stock_status !== 'IN_STOCK' || hasReachedLimit}
                variant='cutLeft'
                size='small'
                sx={(theme) => ({
                  minWidth: 'unset',
                  width: '36px',
                  height: '36px',
                  boxSizing: 'border-box',
                  p: '0px!important',
                  textDecoration: 'underline',
                  '& .MuiLoadingButton-loadingIndicator': {
                    color: theme.palette.primary.contrastText,
                  },
                  '&.Mui-disabled': {
                    opacity: 0.25,
                  },
                })}
                aria-label='AddToCart'
                label={
                  <IconSvg
                    size='medium'
                    src={iconPlus}
                    sx={{
                      fontSize: '19px!important',
                      marginRight: '3px',
                    }}
                  />
                }
                product={{ ...props }}
              />
            )}
          </Box>
          {children}
        </>
      )}
      <PromotionLabel {...labelData} />
    </ButtonBase>
  )
}

ProductListItem.selectors = { ...selectors, ...ProductListPrice.selectors }
